<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()" :disabled="isSaving" type="button"
						class="btn btn-info mt-3 float-left">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right" to="/admin/Panel/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="id" class="col-sm-2 col-form-label">單版編號</label>
							<div class="col-sm-10">
								<input v-model="model.id" type="text" class="form-control" id="id" name="id" readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="vendorch" class="col-sm-2 col-form-label">廠商中文</label>
							<div class="col-sm-10">
								<input v-model="model.vendorch" type="text" class="form-control" id="vendorch"
									name="vendorch" required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="vendoren" class="col-sm-2 col-form-label">廠商英文</label>
							<div class="col-sm-10">
								<input v-model="model.vendoren" type="text" class="form-control" id="vendoren"
									name="vendoren" required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="panelname" class="col-sm-2 col-form-label">版子型號</label>
							<div class="col-sm-10">
								<input v-model="model.panelname" type="text" class="form-control" id="panelname"
									name="panelname" required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="power" class="col-sm-2 col-form-label">發電量(W)</label>
							<div class="col-sm-10">
								<input v-model="model.power" type="text" class="form-control" id="power" name="power" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="meff" class="col-sm-2 col-form-label">模組效率</label>
							<div class="col-sm-10">
								<input v-model="model.meff" type="text" class="form-control" id="meff" name="meff" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="vmp" class="col-sm-2 col-form-label">最大輸出功率電壓Vmp(V) </label>
							<div class="col-sm-10">
								<input v-model="model.vmp" type="text" class="form-control" id="vmp" name="vmp" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="imp" class="col-sm-2 col-form-label">最大輸出功率電電流(A)</label>
							<div class="col-sm-10">
								<input v-model="model.imp" type="text" class="form-control" id="imp" name="imp" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="voc" class="col-sm-2 col-form-label">開路電壓Voc(V)</label>
							<div class="col-sm-10">
								<input v-model="model.voc" type="text" class="form-control" id="voc" name="voc" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="isc" class="col-sm-2 col-form-label">短路電流Isc(A)</label>
							<div class="col-sm-10">
								<input v-model="model.isc" type="text" class="form-control" id="isc" name="isc" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="diffw" class="col-sm-2 col-form-label">輸出功率公差 </label>
							<div class="col-sm-10">
								<input v-model="model.diffw" type="text" class="form-control" id="diffw" name="diffw" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="note" class="col-sm-2 col-form-label">備註</label>
							<div class="col-sm-10">
								<input v-model="model.note" type="text" class="form-control" id="note" name="note" />
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
import LayoutDiv from '@/components/LayoutDiv.vue';
import { getPanel, postPanel } from '@/api/Panel.js'
import toastr from 'toastr'

export default {
	name: 'PanelEdit',
	components: {
		LayoutDiv,
	},
	data() {
		return {
			model: {
				id: '',
				vendorch: '',
				vendoren: '',
				panelname: '',
				power: '',
				meff: '',
				vmp: '',
				imp: '',
				voc: '',
				isc: '',
				diffw: '',
				note: '',

			},
			isSaving: false,
		};
	},
	created() {
		const id = this.$route.params.id;

		getPanel(id).then(rep => {
			this.model = rep.data;
		});

	},
	methods: {
		checkFields() {
			let test = true;
			if (this.model.vendorch == "") test = this.pickupWarning("廠牌中文欄位必填");
			if (this.model.vendoren == "") test = this.pickupWarning("廠牌英文欄位必填");
			if (this.model.panelname == "") test = this.pickupWarning("版子型號必填");
			return test;
		},
		pickupWarning(message) {
			toastr.error(message);
			return false;
		},

		handleSave() {
			if (this.isSaving == true) return;
			this.isSaving = true;

			if (this.checkFields() == false) {
				this.isSaving = false
				return false;
			}
			postPanel(this.model).then(rep => {

				this.isSaving = false;
				return rep;
			})
				.catch(error => {
					this.isSaving = false;
					return error
				});
		},
	},
};
</script>